import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'asc',
			order_by: 'created_at',
			offset: 0,
			limit: 15,
		}
		const { data } = await useJwt.get(API_ROUTES.orderSchedule.get, params)

		return data
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.orderSchedule.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.orderSchedule.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.patch(`${API_ROUTES.orderSchedule.update}/${id}`, data)
	},
}
